<template>
  <Toast />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '350px' }"
    header="APERURA DE CAJA"
    :modal="true"
    @hide="ocultarModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field" v-if="permitirApertura">
          <h3 class="p-invalid">Debes cerrar tu caja abierta</h3>
        </div>
        <div class="field col-12 md:col-12">
          <span
            ><strong>SELECCIONE SUCURSAL:</strong
            ><span class="p-invalid">*</span>
          </span>
          <Dropdown
            v-model="sucursalSelect"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione una sucursal"
            @change="getTipoNegocio"
          >
          </Dropdown>
          <small class="p-invalid" v-if="errors.sucursal_id">{{
            errors.sucursal_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-12" v-show="false">
          <span
            ><strong>SELECCIONE PUNTO DE VENTA:</strong
            ><span class="p-invalid">*</span>
          </span>
          <Dropdown
            v-model="puntoVentaSelect"
            :options="puntosVentas"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione un punto de venta"
          >
          </Dropdown>
          <small class="p-invalid" v-if="errors.punto_venta_id">{{
            errors.punto_venta_id[0]
          }}</small>
        </div>
        <div
          :class="
            tipo_negocio == 2
              ? 'field col-12 md:col-6'
              : 'field col-12 md:col-12'
          "
        >
          <span><strong>MONTO INICIAL: </strong></span>
          <InputNumber
            v-model="data_caja.saldo_inicial"
            :min="0"
            locale="de-DE"
            input-class="text-right"
            :minFractionDigits="2"
          />
          <small class="p-invalid" v-if="errors.saldo_inicial">{{
            errors.saldo_inicial[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6" v-if="this.tipo_negocio == 2">
          <span
            ><strong>MEDIDOR INICIO: </strong
            ><span class="p-invalid">*</span></span
          >
          <InputNumber
            v-model="data_caja.lectura_inicio"
            :min="0"
            locale="de-DE"
            input-class="text-right"
            :minFractionDigits="2"
            @keyup.enter="guardarAperturaCaja"
          />
          <small class="p-invalid" v-if="errors.lectura_inicio">{{
            errors.lectura_inicio[0]
          }}</small>
        </div>
        <div class="field" v-show="false">
          <span
            ><strong>ESTADO: </strong> <span class="p-invalid">*</span></span
          >
          <Dropdown
            v-model="data_caja.estado"
            :disabled="!data_caja.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-danger p-button-lg"
        @click="ocultarModal"
        :disabled="enviado || permitirApertura"
      />
      <Button
        label="APERTURAR"
        icon="pi pi-lock-open"
        class="p-button-success p-button-lg"
        @click="guardarAperturaCaja"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import SucursalService from "@/service/SucursalService";
import CajaService from "@/service/CajaService";
import PuntoVentaService from "@/service/PuntoVentaService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    caja: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_caja: this.caja,
      data_medidor: this.medidor,
      enviado: false,
      sucursalSelect: null,
      sucursales: [],
      estados: [
        { label: "Cancelado", value: 0 },
        { label: "Abierto", value: 1 },
        { label: "Cerrado", value: 2 },
      ],
      errors: {},
      permitirApertura: false,
      /* saldo_anterior: 0, */
      puntoVentaSelect: null,
      puntosVentas: [],
      tipo_negocio: null,
    };
  },
  sucursalService: null,
  cajaService: null,
  puntoVentaService: null,
  created() {
    this.sucursalService = new SucursalService();
    this.cajaService = new CajaService();
    this.puntoVentaService = new PuntoVentaService();
  },
  mounted() {
    this.obtenerSucursales();
    /* this.getSaldoAnterior(); */
    this.getPuntosVentas();
  },
  methods: {
    getTipoNegocio() {
      this.sucursales.forEach((sucursal) => {
        if (sucursal.id == this.sucursalSelect) {
          this.tipo_negocio = sucursal.tipo_negocio;
        }
      });
    },
    getPuntosVentas() {
      this.puntoVentaService.getPuntoVentaAll().then((response) => {
        this.puntosVentas = response.punto_ventas || [];
        this.puntosVentas.forEach((puntoVenta) => {
          if (puntoVenta.id == 1) {
            this.puntoVentaSelect = puntoVenta.id;
          }
        });
      });
    },

    /* getSaldoAnterior() {
      this.cajaService.getSaldoAnterior().then((response) => {
        this.data_caja.saldo_inicial = parseFloat(response.saldo_anterior);
        this.saldo_anterior = parseFloat(response.saldo_anterior);
      });
    }, */
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.permitirApertura = true;
        } else {
          this.permitirApertura = false;
        }
      });
    },

    obtenerSucursales() {
      this.sucursalService.getSucursalesAll().then((response) => {
        this.sucursales = response;
        this.sucursales.forEach((sucursal) => {
          //cargo al select la sucursal con id 1
          if (sucursal.id == 1) {
            this.sucursalSelect = sucursal.id;
            this.tipo_negocio = sucursal.tipo_negocio;
          }
        });
      });
    },
    ocultarModal() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },

    validacionesForm() {
      if (this.tipo_negocio == 2 && this.data_caja.lectura_inicio == null) {
        this.errors.lectura_inicio = ["El campo medidor inicio es requerido"];
      }
      return Object.keys(this.errors).length === 0;
    },

    guardarAperturaCaja() {
      this.errors = {};

      if (!this.validacionesForm()) {
        return;
      }

      this.enviado = true;
      if (this.caja.id) {
        let tmp = {
          ...this.data_caja,
          estado: this.data_caja.estado.value,
          sucursal_id: this.sucursalSelect.id,
          punto_venta_id: this.puntoVentaSelect,
          lectura_fin: this.data_caja.lectura_inicio,
          tipo_negocio: this.tipo_negocio,
        };
        this.cajaService.updatedCaja(tmp).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarModal();
          }
          this.enviado = false;
        });
      } else {
        let tmp = {
          ...this.data_caja,
          estado: this.data_caja.estado.value,
          sucursal_id: this.sucursalSelect,
          fecha_cierre: null,
          punto_venta_id: this.puntoVentaSelect,
          lectura_inicio: this.data_caja.lectura_inicio,
          tipo_negocio: this.tipo_negocio,
        };
        this.cajaService.registrarAperturaCaja(tmp).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            // this.medidorService.registrarMedidorInicio(this.data_caja)
            this.$toast.add({
              severity: "success",
              summary: "Éxitos!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarModal();
            this.$router.push({ name: "nueva_venta" });
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.verificarCajaAbierta();
      this.mostrarModal = val;
    },
    caja(val) {
      this.data_caja = val;
      /* this.data_caja.saldo_inicial = this.saldo_anterior; */
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
