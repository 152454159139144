<template>
  <Toast />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    :header="'CIERRE DE CAJA: #' + data_cajacierre.id"
    :modal="true"
    @hide="ocultarModal"
    class="p-fluid"
  >
    <div class="col-12">
      <Divider align="left" class="mb-0 mt-0">
        <div class="inline-flex align-items-center">
          <i class="pi pi-unlock"></i>
          <b>DATOS DE APERTURA</b>
        </div>
      </Divider>
      <div class="p-fluid formgrid grid p-fondo">
        <div class="field col-12 md:col-6 mb-0">
          <label for=""
            ><strong>USUARIO : </strong>{{ data_cajacierre.nombre_user }}</label
          >
        </div>
        <div class="field col-12 md:col-6 mb-0">
          <label for=""
            ><strong>SUCURSAL : </strong
            >{{ data_cajacierre.nombre_sucursal }}</label
          >
        </div>
        <div class="field col-12 md:col-6 mb-0">
          <strong> MONTO INICIAL : </strong
          ><span style="font-size: 1.1em">
            {{ convertirNumeroGermanicFormat(data_cajacierre.saldo_inicial) }}
          </span>
        </div>
        <div class="field col-12 md:col-6 mb-0">
          <label for=""
            ><strong>FECHA APERTURA:</strong>
            {{ data_cajacierre.fecha_apertura }}</label
          >
        </div>
      </div>
      <Divider align="left" v-if="'Caja Ver Detalle' in auth.user.permissions">
        <div class="inline-flex align-items-center">
          <i class="pi pi-lock"></i>
          <b>DATOS DE CIERRE:</b>
          <span
            style="
              background-color: #d8fadb;
              border-radius: 2px;
              padding: 1px;
              color: black;
            "
            >(Expresado en Bs.)</span
          >
        </div>
      </Divider>

      <div
        class="p-fluid formgrid grid p-fondo2"
        v-if="'Caja Ver Detalle' in auth.user.permissions"
      >
        <div class="field col-12 md:col-4 mb-0">
          <strong> TOTAL VENTAS : </strong
          ><span style="font-size: 1.2em; font-weight: bold">
            {{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_ventas
              )
            }}
          </span>
        </div>
        <div class="field col-12 md:col-4 mb-0">
          <strong>TOTAL EFECTIVO: </strong
          ><span style="font-size: 1.2em; font-weight: bold">{{
            convertirNumeroGermanicFormat(
              data_cajacierre.calculo_total_saldo_caja
            )
          }}</span>
        </div>
        <!-- <div class="field col-12 md:col-4">
						<strong>TRANSFERENCIAS : </strong
						>{{
							convertirNumeroGermanicFormat(
								data_cajacierre.calculo_total_ventas_metodos_pago
							)
						}}
					</div> -->
        <div
          class="field col-12 md:col-4 mb-0"
          v-if="data_cajacierre.detalle_suma_cobros_x_metodos_pago.length > 0"
        >
          <div
            v-for="(
              item, index
            ) in data_cajacierre.detalle_suma_cobros_x_metodos_pago"
            :key="index"
          >
            <strong>{{ item.nombre }} : </strong
            ><span style="font-size: 1.2em; font-weight: bold">
              {{ convertirNumeroGermanicFormat(item.total) }}
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-4 mb-0">
          <strong> TOTAL INGRESOS: </strong>
          <span style="font-size: 1.2em; color: green; font-weight: bold">
            {{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_ingresos
              )
            }}
          </span>
        </div>
        <div class="field col-12 md:col-4 mb-0">
          <strong> TOTAL EGRESOS: </strong>
          <span style="font-size: 1.2em; color: red; font-weight: bold">
            {{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_egresos
              )
            }}
          </span>
          <span v-if="data_cajacierre.calculo_total_egresos > 0">
            <br />
            <span class="mt-0">
              {{
                (data_cajacierre.calculo_total_pago_comision > 0
                  ? "Comisión: " +
                    convertirNumeroGermanicFormat(
                      data_cajacierre.calculo_total_pago_comision
                    )
                  : "") +
                (data_cajacierre.calculo_total_almuerzo > 0
                  ? (data_cajacierre.calculo_total_pago_comision > 0
                      ? ", "
                      : "") +
                    "Almuerzo: " +
                    convertirNumeroGermanicFormat(
                      data_cajacierre.calculo_total_almuerzo
                    )
                  : "")
              }}
            </span>
          </span>
        </div>
        <!-- <div class="field col-12 md:col-4">
            <strong>VENTAS AL CR&Eacute;DITO: </strong
            >{{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_a_credito
              )
            }}
          </div> -->
        <div class="field col-12 md:col-4 mb-0">
          <strong> DIFERENCIA : </strong>
          <span
            v-if="diferencia > 0"
            style="font-size: 1.2em; color: green; font-weight: bold"
            >{{
              convertirNumeroGermanicFormat(diferencia ? diferencia : 0)
            }}</span
          >
          <span
            v-else
            style="font-size: 1.2em; color: red; font-weight: bold"
            >{{
              convertirNumeroGermanicFormat(diferencia ? diferencia : 0)
            }}</span
          >
        </div>
        <div class="field col-12 md:col-4 mb-0">
          <strong> SALDO CAJA EN SISTEMA: </strong>
          <span style="font-size: 1.2em; color: green; font-weight: bold">
            {{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_saldo_caja
              )
            }}
          </span>
        </div>
        <div class="field col-12 md:col-4 mb-0">
          <strong> SALDO EN CAJA: </strong>
          <span style="font-size: 1.2em; font-weight: bold">
            {{ convertirNumeroGermanicFormat(saldo_caja ? saldo_caja : 0) }}
          </span>
        </div>
      </div>
      <Divider align="left">
        <div class="inline-flex align-items-center">
          <i class="pi pi-cog"></i>
          <b>OPERACIONES</b>
        </div>
      </Divider>
      <div class="p-fluid formgrid grid">
        <table class="field col-12 md:col-12">
          <thead>
            <tr v-show="false">
              <td><strong>FECHA DE CIERRE:</strong></td>
              <td>
                <Calendar
                  v-model="data_cajacierre.fecha_cierre"
                  :showIcon="true"
                  dateFormat="dd/mm/yy"
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong
                  >RECUENTO EFECTIVO: <small class="p-invalid">*</small></strong
                >
              </td>
              <td>
                <InputNumber
                  id="recuento_efectivo"
                  inputClass="text-right"
                  :min="0"
                  :showButtons="true"
                  autofocus
                  v-model="data_cajacierre.recuento_efectivo"
                  :minFractionDigits="2"
                  locale="de-DE"
                  placeholder="Ingrese el Monto total en efectivo"
                  :class="{ 'p-invalid': errors.recuento_efectivo }"
                />
                <small class="p-invalid" v-if="errors.recuento_efectivo">{{
                  errors.recuento_efectivo[0]
                }}</small>
              </td>
            </tr>
            <tr v-if="data_cajacierre.tipo_negocio == 2">
              <td>
                <strong
                  >LECTURA FINAL: <small class="p-invalid">*</small></strong
                >
              </td>
              <td>
                <InputNumber
                  id="lectura_fin"
                  inputClass="text-right"
                  :min="0"
                  :showButtons="true"
                  v-model="data_cajacierre.lectura_fin"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                  placeholder="Ingrese el Lectura final del Medidor"
                  :class="{ 'p-invalid': errors.lectura_fin }"
                />
                <small class="p-invalid" v-if="errors.lectura_fin">{{
                  errors.lectura_fin[0]
                }}</small>
              </td>
            </tr>
            <tr>
              <td>
                <strong>OBSERVACIÓN: </strong>
              </td>
              <td>
                <Textarea
                  v-model="data_cajacierre.observacion"
                  rows="1"
                  autoResize
                />
                <small class="p-invalid" v-if="errors.observacion">{{
                  errors.observacion[0]
                }}</small>
              </td>
            </tr>
            <!--  <tr>
              <td></td>
              <td>
                <InputNumber
                  v-if="data_cajacierre.enviar_a_banco"
                  inputClass="text-right"
                  :min="0"
                  :showButtons="true"
                  v-model="data_cajacierre.monto_entregar"
                  :minFractionDigits="2"
                  locale="de-DE"
                  placeholder="Ingrese el Monto a enviar a Caja Principal"
                />
                <small class="p-invalid" v-if="errors.monto_entregar">{{
                  errors.monto_entregar[0]
                }}</small>
              </td>
            </tr> -->
          </thead>
        </table>
      </div>
      <div
        class="p-fluid formgrid grid"
        v-if="'Banco Listar' in auth.user.permissions"
      >
        <div class="field col-12 md:col-8">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <strong>ENTREGAR SOBRE?: </strong>
              <Checkbox
                v-model="enviar_a_banco"
                :binary="true"
                class="ml-1"
                @change="cargarBancos"
              />
            </span>
            <Dropdown
              v-if="enviar_a_banco"
              v-model="bancoSelected"
              :options="bancos"
              optionLabel="descripcion"
              optionValue="id"
              placeholder="Seleccione la Caja Principal"
              :class="{ 'p-invalid': errors.bancoSelected }"
            >
              <template #option="slotProps">
                <span class="font-weight-bold">
                  {{ slotProps.option.numero_cuenta ?? "" }} -
                  {{ slotProps.option.descripcion ?? "" }}
                </span>
              </template>
            </Dropdown>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <InputNumber
            v-if="enviar_a_banco"
            inputClass="text-right"
            :min="0"
            :showButtons="true"
            v-model="monto_efectivo"
            :minFractionDigits="2"
            locale="de-DE"
            placeholder="Ingrese el Monto a enviar a Caja Principal"
          />
          <small class="p-invalid" v-if="errors.monto_efectivo">{{
            errors.monto_efectivo[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarModal"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarCajaCierre"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import CajaService from "@/service/CajaService";
import { useAuth } from "@/stores";
import BancoService from "@/service/BancoService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cajacierre: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      errors: {},
      enviado: false,
      mostrarModal: this.show,
      data_cajacierre: this.cajacierre,
      bancoSelected: null,
      bancos: [],
      enviar_a_banco: false,
      monto_efectivo: 0,
    };
  },
  cajacierreService: null,
  auth: null,
  bancoService: null,
  computed: {
    saldo_caja() {
      return parseFloat(this.data_cajacierre.recuento_efectivo);
    },
    diferencia() {
      return (
        parseFloat(this.data_cajacierre.recuento_efectivo) -
        parseFloat(this.data_cajacierre.calculo_total_saldo_caja)
      );
    },
  },
  created() {
    this.cajacierreService = new CajaService();
    this.auth = useAuth();
    this.bancoService = new BancoService();
  },
  methods: {
    cargarBancos() {
      this.bancoService.getBancosAll().then((data) => {
        this.bancos = data.bancos;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    ocultarModal() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    validacionesForm() {
      if (
        this.data_cajacierre.tipo_negocio == 2 &&
        !this.data_cajacierre.lectura_fin
      ) {
        this.errors.lectura_fin = [
          "DEBE INGRESAR LA LECTURA FINAL DEL MEDIDOR",
        ];
      }
      if (this.enviar_a_banco && !this.bancoSelected) {
        this.errors.bancoSelected = ["DEBE SELECCIONAR UNA CAJA PRINCIPAL"];
      }
      return Object.keys(this.errors).length === 0;
    },
    guardarCajaCierre() {
      this.errors = {};
      if (!this.validacionesForm()) {
        return;
      }
      this.enviado = true;
      let tmp = {
        ...this.data_cajacierre,
        estado: this.data_cajacierre.estado.value,
        total_ventas: this.data_cajacierre.calculo_total_ventas,
        diferencia: this.diferencia,
        saldo_caja: this.saldo_caja,
        recuento_metodos_pago:
          this.data_cajacierre.detalle_suma_cobros_x_metodos_pago,
        lectura_fin: this.data_cajacierre.lectura_fin,
        enviar_a_banco: this.enviar_a_banco,
        banco_id: this.bancoSelected,
        monto_efectivo: this.monto_efectivo,
      };

      this.cajacierreService.updatedCaja(tmp).then((data) => {
        if (Object.prototype.hasOwnProperty.call(data, "errors")) {
          this.errors = data.errors;
        } else {
          if (data.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.mensaje,
              life: 3000,
            });
            this.enviado = false;
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarModal();
            this.enviado = false;
          }
        }
      });
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cajacierre(val) {
      val.diferencia = 0;
      val.recuento_efectivo = null;
      val.monto_entregar = 0;
      val.fecha_cierre = new Date();
      val.lectura_fin = null;
      this.data_cajacierre = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
  font-weight: bold;
}
.p-fondo {
  background-color: #d8fadb;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #000000;
}
.p-fondo2 {
  background-color: #f8d7da;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #000000;
}
.p-inputgroup-addon {
  background-color: #ffffff;
  font-weight: bold;
  color: #000000;
  border: none;
}
</style>
